import { default as create_45orderuyAahyngGLMeta } from "/home/dashboard/app:client/pages/101red/dropship/create-order.vue?macro=true";
import { default as kebijakan_45dan_45privasiR1HO9NSDf2Meta } from "/home/dashboard/app:client/pages/101red/p/kebijakan-dan-privasi.vue?macro=true";
import { default as syarat_45dan_45kondisivMMXWmmjXYMeta } from "/home/dashboard/app:client/pages/101red/p/syarat-dan-kondisi.vue?macro=true";
import { default as _91id_93qhguw7ta7UMeta } from "/home/dashboard/app:client/pages/101red/x/[id].vue?macro=true";
import { default as indexiRcwmpvFq5Meta } from "/home/dashboard/app:client/pages/101red/x/index.vue?macro=true";
import { default as forgot_45passwordECRiGDOas3Meta } from "/home/dashboard/app:client/pages/forgot-password.vue?macro=true";
import { default as indexQgTOdgL3QMMeta } from "/home/dashboard/app:client/pages/index.vue?macro=true";
import { default as loginCr8Nd2WSp8Meta } from "/home/dashboard/app:client/pages/login.vue?macro=true";
import { default as logoutOdzZDIn3wSMeta } from "/home/dashboard/app:client/pages/logout.vue?macro=true";
import { default as edityo7jSN997HMeta } from "/home/dashboard/app:client/pages/profile/edit.vue?macro=true";
import { default as indexTx5EUOXGLkMeta } from "/home/dashboard/app:client/pages/profile/index.vue?macro=true";
import { default as vacationlHmMdpQG6FMeta } from "/home/dashboard/app:client/pages/profile/vacation.vue?macro=true";
import { default as wishlistTUqcOjXEv2Meta } from "/home/dashboard/app:client/pages/profile/wishlist.vue?macro=true";
import { default as registerA91WXhwh71Meta } from "/home/dashboard/app:client/pages/register.vue?macro=true";
import { default as indexsgiz5KSf3FMeta } from "/home/dashboard/app:admin/pages/xadmin/index.vue?macro=true";
import { default as indexnfBH8SEeCCMeta } from "/home/modules/app_admin/views/pages/index.vue?macro=true";
import { default as forgot_45password8OHDiWyndFMeta } from "/home/modules/app_admin/views/pages/forgot-password.vue?macro=true";
import { default as loginEwuG0DtJmOMeta } from "/home/modules/app_admin/views/pages/login.vue?macro=true";
import { default as logoutOfOCnJALDpMeta } from "/home/modules/app_admin/views/pages/logout.vue?macro=true";
import { default as registeryUuapWuC6IMeta } from "/home/modules/app_admin/views/pages/register.vue?macro=true";
import { default as indexSEFdrVm3abMeta } from "/home/modules/app_admin/views/pages/privileges/index.vue?macro=true";
export default [
  {
    name: "101red-dropship-create-order",
    path: "/101red/dropship/create-order",
    meta: create_45orderuyAahyngGLMeta || {},
    component: () => import("/home/dashboard/app:client/pages/101red/dropship/create-order.vue")
  },
  {
    name: "101red-p-kebijakan-dan-privasi",
    path: "/101red/p/kebijakan-dan-privasi",
    meta: kebijakan_45dan_45privasiR1HO9NSDf2Meta || {},
    component: () => import("/home/dashboard/app:client/pages/101red/p/kebijakan-dan-privasi.vue")
  },
  {
    name: "101red-p-syarat-dan-kondisi",
    path: "/101red/p/syarat-dan-kondisi",
    meta: syarat_45dan_45kondisivMMXWmmjXYMeta || {},
    component: () => import("/home/dashboard/app:client/pages/101red/p/syarat-dan-kondisi.vue")
  },
  {
    name: "101red-x-id",
    path: "/101red/x/:id()",
    meta: _91id_93qhguw7ta7UMeta || {},
    component: () => import("/home/dashboard/app:client/pages/101red/x/[id].vue")
  },
  {
    name: "101red-x",
    path: "/101red/x",
    meta: indexiRcwmpvFq5Meta || {},
    component: () => import("/home/dashboard/app:client/pages/101red/x/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordECRiGDOas3Meta || {},
    component: () => import("/home/dashboard/app:client/pages/forgot-password.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexQgTOdgL3QMMeta || {},
    component: () => import("/home/dashboard/app:client/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginCr8Nd2WSp8Meta || {},
    component: () => import("/home/dashboard/app:client/pages/login.vue")
  },
  {
    name: "logout",
    path: "/logout",
    meta: logoutOdzZDIn3wSMeta || {},
    component: () => import("/home/dashboard/app:client/pages/logout.vue")
  },
  {
    name: "profile-edit",
    path: "/profile/edit",
    meta: edityo7jSN997HMeta || {},
    component: () => import("/home/dashboard/app:client/pages/profile/edit.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexTx5EUOXGLkMeta || {},
    component: () => import("/home/dashboard/app:client/pages/profile/index.vue")
  },
  {
    name: "profile-vacation",
    path: "/profile/vacation",
    meta: vacationlHmMdpQG6FMeta || {},
    component: () => import("/home/dashboard/app:client/pages/profile/vacation.vue")
  },
  {
    name: "profile-wishlist",
    path: "/profile/wishlist",
    meta: wishlistTUqcOjXEv2Meta || {},
    component: () => import("/home/dashboard/app:client/pages/profile/wishlist.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerA91WXhwh71Meta || {},
    component: () => import("/home/dashboard/app:client/pages/register.vue")
  },
  {
    name: "xadmin",
    path: "/xadmin",
    component: () => import("/home/dashboard/app:admin/pages/xadmin/index.vue")
  },
  {
    name: "app_admin-index",
    path: "/admin/",
    meta: { ...(indexnfBH8SEeCCMeta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/index.vue")
  },
  {
    name: "app_admin-forgot-password",
    path: "/admin/forgot-password",
    meta: { ...(forgot_45password8OHDiWyndFMeta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/forgot-password.vue")
  },
  {
    name: "app_admin-login",
    path: "/admin/login",
    meta: { ...(loginEwuG0DtJmOMeta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/login.vue")
  },
  {
    name: "app_admin-logout",
    path: "/admin/logout",
    meta: { ...(logoutOfOCnJALDpMeta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/logout.vue")
  },
  {
    name: "app_admin-register",
    path: "/admin/register",
    meta: { ...(registeryUuapWuC6IMeta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/register.vue")
  },
  {
    name: "app_admin-privileges",
    path: "/admin/privileges",
    meta: { ...(indexSEFdrVm3abMeta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/privileges/index.vue")
  }
]