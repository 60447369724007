import Pusher from "pusher-js";

export default defineNuxtPlugin(({}) => {
    const config = useRuntimeConfig();

    const sx = new Pusher(config.public.pusher_key, {
        cluster: config.public.pusher_cluster,
    });

    // const sx = () => {};
    return {
        provide: {
            // pusher: sx,
            pusher: (module: string) => {
                return sx.subscribe(module);
            },
            pushNotif: (title: string, message: string) => {
                if ("Notification" in window) {
                    // Ask for permission if not already granted
                    if (Notification.permission === "granted") {
                        // Show the notification
                        new Notification(title, {
                            body: message,
                            // icon: "path/to/icon.png", // optional
                        });
                    } else if (Notification.permission !== "denied") {
                        Notification.requestPermission().then((permission) => {
                            if (permission === "granted") {
                                // Show the notification after permission is granted
                                new Notification(title, {
                                    body: message,
                                    // icon: "path/to/icon.png", // optional
                                });
                            }
                        });
                    }
                } else {
                    console.log(
                        "This browser does not support desktop notifications.",
                    );
                }
            },
        },
    };
});

// onMounted(async () => {
//     await $pusher('app_sample').bind(
//         'task_sample',
//         (x: string | Record<string, unknown>) => {
//             console.log(x)
//             toast.add({ title: x })
//         },
//     )
// })
